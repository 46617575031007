import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ScrollService } from '../../../services/scroll.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  brandName!:string
  language = localStorage.getItem('language') || 'en';

  constructor(
    private ScrollService: ScrollService,
    public router: Router,
  ) { }

  ngOnInit(): void {
  }

  
  scrollTo(idName: string) {
    if (this.router.isActive('/home', true)) {
      try {
        this.ScrollService.scrollToElementById(idName);
      } catch (err) {
        console.error(err);
      }
    } else {
      this.router.navigate(['home']);
      setTimeout(() => {
        this.scrollTo(idName);
      }, 100);
    }
  }

  onSignUp(){
    this.router.navigate(['/registration'],{queryParams:{brandName:this.brandName}})
  }

}
