<div class="sticky-top" [ngClass]="{'transparent-navbar': yScrollValue< 100 , 'white-navbar': yScrollValue >= 100 || isMobileNavOpen || forceWhite}">
    <div class="container-fluid d-flex justify-content-center">
        <nav class="navbar navbar-expand-lg py-3 col-11 py-lg-0">
            <img src="assets/ours/light-logo.png" alt="" class="col-4 col-md-3 col-lg-1" *ngIf="forceWhite">
            <img src="assets/ours/light-logo.png" alt="" class="col-4 col-md-3 col-lg-1" [hidden]="yScrollValue< 100 || forceWhite">
            <img src="assets/ours/dark-logo.png" alt="" class="col-4 col-md-3 col-lg-1" [hidden]="yScrollValue>= 100 || isMobileNavOpen || forceWhite ">

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" (click)="isMobileNavOpen = !isMobileNavOpen">
                <span class="fa fa-bars"></span>
            </button>
    
            <div class="collapse navbar-collapse justify-content-end" style="gap: 50px;" id="navbarCollapse">
                <div class="navbar-nav">
                    <a [routerLink]="['/home']" class="nav-item py-4 nav-link" [ngClass]="{'selected-section': sectionName == 'hero' }" translate>Home</a>
                    <a [routerLink]="['/home/channels']" class="nav-item py-4 nav-link" [ngClass]="{'selected-section': sectionName == 'channels' }"translate>Channels</a>
                    <a [routerLink]="['/home/features']" class="nav-item py-4 nav-link" [ngClass]="{'selected-section': sectionName == 'features' }"translate>Features</a>
                    <a [routerLink]="['/home/plans']" class="nav-item py-4 nav-link" [ngClass]="{'selected-section': sectionName == 'plans' }"translate>Plans</a>
                    <a [routerLink]="['/home/about-us']" class="nav-item py-4 nav-link" [ngClass]="{'selected-section': sectionName == 'about' }"translate>About</a>
                    <a class="nav-item py-4 nav-link d-flex" translate *ngIf="language == 'ar'" (click)="changeLangage('en')">English</a>
                    <span class="nav-item py-4 nav-link arabic-font d-flex cursor-pointer" translate *ngIf="language == 'en'" (click)="changeLangage('ar')">العربية</span>
                </div>
    
                <div class="d-flex" style="gap: 5px;">
                    <!-- <a [href]="sanitizedURL" class="btn btn-light" translate>Sign In</a> -->
                    <a [routerLink]="['/registration']" class="btn btn-primary" translate>Sign In</a>
                </div>
            </div>
        </nav>
      
      </div>
</div>

