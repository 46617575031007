<div class="container-fluid text-light mt-5" style="background: #061429;">
    <div class="container">
        <div class="row gx-5">
            <div class="col-lg-4 col-md-6 footer-about">
                <div class="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                    <a href="index.html" class="navbar-brand">
                        <img width="200px" src="assets/ours/all-white-logo.png" alt="">
                    </a>
                    <p class="mt-3 mb-4"translate>The best solution for restaurants and hotels in the region.</p>
                </div>
            </div>
            <div class="col-lg-8 col-md-6">
                <div class="row gx-5">
                    <div class="col-lg-6 col-md-12 pt-5 mb-5">
                        <div class="section-title-sm position-relative pb-3 mb-4" [ngClass]="{'section-title': language == 'en','section-title-ar': language == 'ar'}">
                            <h3 class="text-light mb-0"translate>Get In Touch</h3>
                        </div>

                        <div class="d-flex mb-2">
                            <i class="bi bi-envelope-open text-primary mx-2"></i>
                            <p class="mb-0">info@bonbell.net</p>
                        </div>
                        <div class="d-flex mb-2">
                            <i class="bi bi-geo-alt text-primary mx-2"></i>
                            <p class="mb-0">{{'Riyadh, KSA'|translate}}: <a href="tel:+966544603856">&#x200E;+966 544 6038 56</a></p>
                        </div>
                        <div class="d-flex mb-2">
                            <i class="bi bi-geo-alt text-primary mx-2"></i>
                            <p class="mb-1">{{'Cairo, Egypt'|translate}}: <a href="tel:+201007193941">&#x200E;+20 100 719 3941</a></p>
                        </div>

                        <div class="d-flex mt-4">
                            <a class="btn btn-primary btn-square mx-2" href="https://ppl.bonbell.net/web/index.php/recruitmentApply/jobs.html"><i class="bi bi-briefcase-fill"></i></a>
                            <a class="btn btn-primary btn-square mx-2" href="https://www.facebook.com/bonbelltechnologies"><i class="fab fa-facebook-f fw-normal"></i></a>
                            <a class="btn btn-primary btn-square mx-2" href="https://www.linkedin.com/company/bonbell/"><i class="fab fa-linkedin-in fw-normal"></i></a>
                            <a class="btn btn-primary btn-square mx-2" href="https://www.instagram.com/bonbelltechnologies/"><i class="fab fa-instagram fw-normal"></i></a>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                        <div class="section-title-sm position-relative pb-3 mb-4" [ngClass]="{'section-title': language == 'en','section-title-ar': language == 'ar'}">
                            <h3 class="text-light mb-0"translate>Popular Links</h3>
                        </div>
                        <div class="link-animated d-flex flex-column justify-content-start">
                            <a class="text-light mb-2 text-decoration-none" [routerLink]="['/home']"><i class="bi bi-arrow-right text-primary mx-2"></i>{{'Home'|translate}}</a>
                            <a class="text-light mb-2 text-decoration-none" [routerLink]="['/home/channels']"><i class="bi bi-arrow-right text-primary mx-2"></i>{{'Our Features'|translate}}</a>
                            <a class="text-light mb-2 text-decoration-none" [routerLink]="['/home/news']"><i class="bi bi-arrow-right text-primary mx-2"></i>{{'Our News'|translate}}</a>
                            <a class="text-light mb-2 text-decoration-none" [routerLink]="['/home/about-us']"><i class="bi bi-arrow-right text-primary mx-2"></i>{{'About Us'|translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
