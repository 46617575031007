import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.scss']
})
export class NewsDetailsComponent implements OnInit {
  routeUrl!: string;
  index!: any;
  news!: any;
  language!: string;

  constructor(
    private route : ActivatedRoute,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.language = localStorage.getItem('language') || 'en'
    this.routeUrl = this.route.snapshot.url[0]?.path
    this.index = this.route.snapshot.paramMap.get('index');
    this.getNews()
  }

  getNews(): any {
    return this.http.get<any>('assets/news.json').subscribe((res)=>{
      this.news = Object.values(res)
      console.log(this.news);
      console.log(this.news[this.index]);
    })

    
  }

}
