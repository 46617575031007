<app-navbar *ngIf="routeUrl && !(routeUrl=='ar' || routeUrl=='en')" [forceWhite]="true"></app-navbar>
<div class="container-fluid py-4 wow fadeInUp" data-wow-delay="0.1s" id="about">
    <div class="container py-4">
        <div class="row g-5">
            <div class="col-lg-7">
                <div class="section-title position-relative pb-3 mb-3">
                    <h1 class="mb-1" *ngIf="language == 'en'" class="text-start">{{news[index]?.title_en}}</h1>
                    <h1 class="mb-1" *ngIf="language == 'ar'" class="text-end">{{news[index]?.title_ar}}</h1>
                </div>
                <div class="d-flex mb-3">
                    <small class="mx-3 badge bg-primary" *ngIf="news[index]?.label">{{news[index]?.label}}</small>
                    <small class="mx-3" *ngIf="news[index]?.createdBy">{{news[index]?.createdBy}}<i class="far fa-user text-primary mx-2"></i></small>
                    <small *ngIf="news[index]?.createdAt">{{news[index]?.createdAt}}<i class="far fa-calendar-alt text-primary mx-2"></i></small>
                </div>
                <p *ngIf="language == 'en'" class="mb-4">{{news[index]?.description_en}}</p>
                <p *ngIf="language == 'ar'" class="mb-4">{{news[index]?.description_ar}}</p>
            </div>
            <div class="col-lg-5" style="min-height: 500px;">
                <div class="position-relative h-100">
                    <img class="position-absolute w-100 h-100 rounded wow bounceIn" data-wow-delay="0.9s" [src]="news[index]?.img" style="object-fit: cover;">
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
