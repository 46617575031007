import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { ScrollService } from '../../../services/scroll.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  sectionName!: string;
  isMobileNavOpen:boolean = false;
  language: string = localStorage.getItem('language') || 'en';
  @Input() forceWhite: boolean = false;
  yScrollValue: number=0;
  sanitizedURL: any;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.yScrollValue = window.pageYOffset || document.documentElement.scrollTop
    if(this.router.isActive('/home', true)){
      if(this.isInViewport('hero')){
        this.sectionName = 'hero'
      }else if(this.isInViewport('channels')){
        this.sectionName = 'channels'
      }else if(this.isInViewport('about')){
        this.sectionName = 'about'
      }else if(this.isInViewport('features')){
        this.sectionName = 'features'
      }else if(this.isInViewport('plans')){
        this.sectionName = 'plans'
      }
    }
  }

  constructor(
    private ScrollService: ScrollService,
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private sanitizer:DomSanitizer
  ) { }

  ngOnInit(): void {
    this.sanitizedURL = this.sanitizer.bypassSecurityTrustUrl(environment.dashboardUrl);
    if(this.router.isActive('/home', true)){
      this.sectionName = 'hero'
    }
  }

  scrollTo(idName: string) {
    if (this.router.isActive('/home', true)) {
      try {
        this.ScrollService.scrollToElementById(idName);
      } catch (err) {
        console.error(err);
      }
    } else {
      this.router.navigate(['home']);
      setTimeout(() => {
        this.scrollTo(idName);
      }, 100);
    }
  }

  isInViewport(element: any) {
    let bounding = document.getElementById(element)?.getBoundingClientRect();
    if (bounding) {
      return (
        bounding.top < (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.left < (window.innerWidth || document.documentElement.clientWidth) &&
        bounding.bottom > 0 &&
        bounding.right > 0
      );
    }
    return null
  }

//   getScrollTop() {
//     return window.pageYOffset || document.documentElement.scrollTop;
// }

changeLangage(lang: string) {
  let htmlTag = this.document.getElementsByTagName(
    'html'
  )[0] as HTMLHtmlElement;
  localStorage.setItem('language', lang);
  this.language = lang;
  this.translateService.setDefaultLang(lang);
  this.translateService.use(lang);
  this.changeCssFile(lang);
  htmlTag.dir = lang === 'ar' ? 'rtl' : 'ltr';
  window.location.reload();
}

changeCssFile(lang: string) {
  let headTag = this.document.getElementsByTagName(
    'head'
  )[0] as HTMLHeadElement;
  let existingLink = this.document.getElementById(
    'langCss'
  ) as HTMLLinkElement;

  let bundleName = lang === 'ar' ? 'arabicStyle.css' : 'englishStyle.css';

  if (existingLink) {
    existingLink.href = bundleName;
  } else {
    let newLink = this.document.createElement('link');
    newLink.rel = 'stylesheet';
    newLink.type = 'text/css';
    newLink.id = 'langCss';
    newLink.href = bundleName;
    headTag.appendChild(newLink);
  }
}
}
